import Payments from "./Payments";

export default class KlarnaPayments extends Payments {
  async setSession() {
    return await this.http.KLARNA.post("payments/v1/sessions", this.getData());
  }

  async placeOrder({ auth_token, session_id }) {
    const idorder = this.cart.IdCart;
    const data = {
      ...this.getData(),
      ...{
        merchant_urls: {
          confirmation: `https://dev.jeanmarcphilippe.com/cart/thanks/accepted/${idorder}`,
          notification: `https://dev.jeanmarcphilippe.com/klarna/notifications?klarna_order_id=${session_id}`,
          // push: 'https://www.example-url.com/push',
          // authorization: 'https://www.example-url.com/authorization'
        },
      },
    };
    return await this.http.KLARNA.post(
      `payments/v1/authorizations/${auth_token}/order`,
      data
    );
  }

  async createOrder({ order_id }) {
    var FormData = require("form-data");
    var formData = new FormData();
    formData.append("KlarnaOrderId", order_id);

    try {
      const response = await this.http.API_AUTH.post(
        "payment/klarna/order",
        formData
      );
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }

    return false;
  }

  get basketKlarna() {
    const basket = [];

    let orderTaxAmount = 0;
    const tax = this.cart?.Country?.TaxRate || 0;

    this.cart?.Products.map((item) => {
      let promotionalPrice = item?.Price?.PromotionalPrice;
      let regularPrice = item?.Price?.RegularPrice;
      let price = regularPrice;
      let discount = 0;
      const quantity = item?.Quantity;

      if (promotionalPrice) {
        discount = this.toFixed2(
          (promotionalPrice.PriceTaxIncl - regularPrice.PriceTaxIncl) * quantity
        );

        price = promotionalPrice;
      }

      const totalAmount = price?.PriceTaxIncl * quantity;
      // 1euro = 100
      const taxRate = tax * 10000; // 0.2 => 20% = 2000
      const total_amount = totalAmount * 100;
      const total_tax_amount =
        total_amount - (total_amount * 10000) / (10000 + taxRate);

      const newItem = {
        type: "physical",
        reference: item?.Reference + "--" + item?.IdCartDetail,
        name: item?.Description?.Title,
        quantity: quantity,

        unit_price: regularPrice?.PriceTaxIncl * 100,
        tax_rate: taxRate,
        total_amount: Number(total_amount.toFixed(2)),
        total_discount_amount: Number(discount.toFixed(2)) * 100 * -1,
        total_tax_amount: Number(total_tax_amount.toFixed(0)),

        // "image_url": "https://www.exampleobjects.com/logo.png",
        // "product_url": "https://www.estore.com/products/f2a8d7e34"
      };

      orderTaxAmount += total_tax_amount;

      basket.push(newItem);
    });

    const carrier = this.carrier;
    if (carrier) {
      const shippingRef = this.carrier.Name + "-" + this.carrier.IdCarrier;

      const taxRate = tax * 10000; // 0.2 => 20% = 2000
      const total_amount = this.shipping?.TaxIncl * 100;
      const total_tax_amount =
        total_amount - (total_amount * 10000) / (10000 + taxRate);

      basket.push({
        type: "shipping_fee",
        reference: shippingRef,
        name: carrier.Name,
        quantity: 1,
        unit_price: this.shipping?.TaxIncl * 100,
        tax_rate: taxRate, // 0.2 => 20% = 2000
        total_amount: total_amount,
        total_discount_amount: 0,
        total_tax_amount: Number(total_tax_amount.toFixed(2)),
      });

      orderTaxAmount += Number(total_tax_amount.toFixed(2));
    }

    const promoCodeCart = this.cart?.Discounts?.PromoCodes;
    promoCodeCart?.map((promoCode) => {
      if (promoCode) {
        basket.push({
          type: "discount",
          reference: promoCode.IdPromoCode,
          name: promoCode.Title,
          quantity: 1,
          unit_price: -1 * promoCode?.Reduction?.Value?.TaxIncl * 100,
          tax_rate: 0,
          total_amount: -1 * promoCode?.Reduction?.Value?.TaxIncl * 100,
          total_discount_amount: 0,
          total_tax_amount: 0,
        });
      }
    });
    const cartRules = this.cart?.Discounts?.CartRules;
    cartRules?.map((cartRule) => {
      if (cartRule) {
        basket.push({
          type: "discount",
          reference: cartRule.IdPromoCode,
          name: cartRule.Title,
          quantity: 1,
          unit_price: -1 * cartRule?.Reduction?.Value?.TaxIncl * 100,
          tax_rate: 0,
          total_amount: -1 * cartRule?.Reduction?.Value?.TaxIncl * 100,
          total_discount_amount: 0,
          total_tax_amount: 0,
        });
      }
    });

    // const priceRules = this.cart?.Discounts?.PriceRules;

    // priceRules?.map((priceRule) => {
    //   if (priceRule) {
    //     basket.push({
    //       type: "discount",
    //       reference: priceRule.IdPriceRule,
    //       name: priceRule.Title,
    //       quantity: 1,
    //       unit_price: -1 * priceRule?.Reduction?.Value?.TaxIncl * 100,
    //       tax_rate: 0,
    //       total_amount: -1 * priceRule?.Reduction?.Value?.TaxIncl * 100,
    //       total_discount_amount: 0,
    //       total_tax_amount: 0,
    //     });
    //   }
    // });

    return { basket, orderTaxAmount }; // price+(price * 0.2)
  }

  getData() {
    const currency = this.cart?.Currency?.IsoCode;
    const country = this.cart?.Country?.CountryIsoCode;
    const locale = this.auth.ctx.i18n.locale;
    const BK = this.basketKlarna;

    const data = {
      intent: "buy",
      purchase_country: country,
      purchase_currency: currency,
      locale: locale,
      order_amount: this.cart?.Total?.ToPay?.TaxIncl * 100,
      order_tax_amount: this.toFixed2(BK.orderTaxAmount),
      order_lines: BK.basket,

      merchant_data: JSON.stringify(this.custom_data),
    };

    return data;
  }
}
