import { AddressService } from "@/services/AddressService.js";

export default {
  data() {
    return {
      addressService: new AddressService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
    };
  },
  computed: {
    addresses() {
      return this.addressService.addresses.List;
    },
    idAddressDelivery: {
      get() {
        return this.getAddressDelivery?.IdAddress;
      },
      async set(idAddress) {
        
      },
    },
    idAddressInvoice: {
      get() {
        return this.getAddressInvoice?.IdAddress;
      },
      async set(idAddress) {
       
      },
    },
    getAddressDelivery() {
      return this.addressService.addressDelivery;
    },
    getAddressInvoice() {
      return this.addressService.addressInvoice;
    },
  },
  methods: {},
  created() {
    // this.addressService.fetchAddresses();
  },
};
